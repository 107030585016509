// extracted by mini-css-extract-plugin
export var blackBackground = "features-module--blackBackground--OiYQl";
export var blockColumns = "features-module--blockColumns--OCNch";
export var collections = "features-module--collections--dtYrl";
export var collectionsBlock = "features-module--collectionsBlock--Lw1F0";
export var collectionsBlockText = "features-module--collectionsBlockText--u7IRC";
export var collectionsExtra = "features-module--collectionsExtra--oYQMS";
export var collectionsExtraVideo = "features-module--collectionsExtraVideo--owhnH";
export var collectionsHeader = "features-module--collectionsHeader--AaiGh";
export var collectionsHeaderText = "features-module--collectionsHeaderText--FZ7vP";
export var comparison = "features-module--comparison--gSNNd";
export var comparisonPictureMobile = "features-module--comparisonPictureMobile--P0A2R";
export var comparisonPictures = "features-module--comparisonPictures--U23lw";
export var comparisonTextBlocks = "features-module--comparisonTextBlocks--NY6Go";
export var comparisonTextRow = "features-module--comparisonTextRow--1ks6A";
export var comparisonTextRowLeft = "features-module--comparisonTextRowLeft--oNojj";
export var comparisonTextRowRight = "features-module--comparisonTextRowRight--jXKr9";
export var ctaBlock = "features-module--ctaBlock--KVNyL";
export var ctaButton = "features-module--ctaButton--t9p-5";
export var desktop = "features-module--desktop--yvFhZ";
export var events = "features-module--events--co43w";
export var eventsBlock = "features-module--eventsBlock--svP96";
export var eventsVideo = "features-module--eventsVideo--GVsqu";
export var grayBackground = "features-module--grayBackground--mTS2s";
export var list = "features-module--list--qikPI";
export var mobile = "features-module--mobile--ygyn9";
export var multipleCalendars = "features-module--multipleCalendars--NJAg+";
export var multipleCalendarsBlock = "features-module--multipleCalendarsBlock--UIyxO";
export var multipleCalendarsPicture3 = "features-module--multipleCalendarsPicture3--wTRlf";
export var multipleCalendarsText = "features-module--multipleCalendarsText--x4IEh";
export var notes = "features-module--notes--al-9o";
export var notesBlock = "features-module--notesBlock--70zh4";
export var notesBlockInner = "features-module--notesBlockInner--9HfUL";
export var notesText = "features-module--notesText--96PYA";
export var pageContent = "features-module--pageContent--N++gN";
export var picture = "features-module--picture--BZILE";
export var review = "features-module--review--APZMv";
export var reviewAuthor = "features-module--reviewAuthor--bN7u0";
export var reviewList = "features-module--reviewList--njbhg";
export var reviewText = "features-module--reviewText--sfzYR";
export var reviewsBlock = "features-module--reviewsBlock--YTLSl";
export var reviewsTitle = "features-module--reviewsTitle--w7xRw";
export var subtitle = "features-module--subtitle--p-jiv";
export var tasks = "features-module--tasks--l4bGS";
export var tasksBlock = "features-module--tasksBlock--79HnD";
export var tasksBlockText = "features-module--tasksBlockText--MeywU";
export var tasksBlocks = "features-module--tasksBlocks--5CJ7H";
export var tasksExtra = "features-module--tasksExtra--fe2VP";
export var tasksExtraVideo = "features-module--tasksExtraVideo--5N4KG";
export var tasksOverdue = "features-module--tasksOverdue--7Y5Mi";
export var tasksOverduePicture = "features-module--tasksOverduePicture--gH5vn";
export var tasksOverduePictureWrapper = "features-module--tasksOverduePictureWrapper--tEn8C";
export var tasksOverdueText = "features-module--tasksOverdueText--AC3Xt";
export var tasksText = "features-module--tasksText--DOhJh";
export var textColumns = "features-module--textColumns--3auB6";
export var timeBlocking = "features-module--timeBlocking--r30uB";
export var timeBlockingContentMobile = "features-module--timeBlockingContentMobile--FNQtO";
export var timeBlockingEditorPicture = "features-module--timeBlockingEditorPicture--T5SA5";
export var timeBlockingExtraBlock = "features-module--timeBlockingExtraBlock--1fNfP";
export var timeBlockingExtraText = "features-module--timeBlockingExtraText--aNy4f";
export var timeBlockingPicture = "features-module--timeBlockingPicture--IGRuo";
export var timeBlockingTop = "features-module--timeBlockingTop--6IAql";
export var timeBlockingVideo = "features-module--timeBlockingVideo--teFFp";
export var upperBlock = "features-module--upperBlock--q0Z2p";
export var weeklyPlanning = "features-module--weeklyPlanning--RS+Ed";
export var weeklyPlanningMain = "features-module--weeklyPlanningMain--t-tbS";
export var weeklyPlanningVideo = "features-module--weeklyPlanningVideo--ZxRnP";