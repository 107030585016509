import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { Url } from '../url';
import * as Text from '../localisation';
import InViewTracker from '../components/in-view-tracker';
import TopMenu from '../components/top-menu';
import Footer from '../components/footer';
import Meta from '../components/meta';
import CtaBlockMobile from '../components/cta-block-mobile';
import Video from '../components/video';

import TimeBlockingMobileVideo from '../images/features-timeblocking-mobile.mp4';
import DatePickerVideo from '../images/date-picker.mp4';
import WeeklyViewVideo from '../images/weekly-view.mp4';
import EventsVideo from '../images/events.mp4';
import CollectionsVideo from '../images/collections.mp4';

import * as classes from './features.module.css';

function CtaBlock() {
  return (
    <div className={classes.ctaBlock}>
      <Link to={Url.GetStarted} className={classNames(classes.ctaButton, classes.desktop)}>
        {Text.GetStarted}
      </Link>
      <CtaBlockMobile />
    </div>
  );
}

function FeaturesPage() {
  return (
    <>
      <Meta
        title={Text.FeaturesTitle}
        url={Url.Main}
      />

      <TopMenu />

      <div className={classes.timeBlockingTop}>
        <div className={classes.pageContent}>
          <InViewTracker name="time_blocking_title">
            <h2>{Text.FeaturesTimeBlockingTitle}</h2>

            <h3 className={classes.subtitle}>{Text.FeaturesTimeBlockingSubTitle}</h3>
          </InViewTracker>

          <InViewTracker name="time_blocking_text">
            <div className={classNames(classes.textColumns, classes.desktop)}>
              <div>
                {Text.FeaturesTimeBlockingText1}
              </div>
              <div>
                {Text.FeaturesTimeBlockingText2}
              </div>
              <div>
                {Text.FeaturesTimeBlockingText3}
              </div>
            </div>

            <ul className={classNames(classes.list, classes.mobile)}>
              <li>{Text.FeaturesTimeBlockingText1}</li>
              <li>{Text.FeaturesTimeBlockingText2}</li>
              <li>{Text.FeaturesTimeBlockingText3}</li>
            </ul>
          </InViewTracker>

          <InViewTracker name="time_blocking_picture" className={classes.desktop}>
            <StaticImage
              src="../images/features-time-blocking.png"
              className={`${classes.picture} ${classes.timeBlockingPicture}`}
              alt={Text.FeaturesTimeBlockingPictureAlt}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </InViewTracker>
        </div>
      </div>

      <Video
        className={classNames(classes.timeBlockingVideo, classes.mobile)}
        video={TimeBlockingMobileVideo}
        label="timblocking"
      />

      <div className={classes.pageContent}>
        <div className={classes.timeBlocking}>
          <InViewTracker className={classes.blockColumns} name="time_blocking_extra">
            <div className={classes.timeBlockingExtraBlock}>
              <h3>{Text.FeaturesTimeBlockingExtra1Title}</h3>
              <div className={classes.timeBlockingExtraText}>
                {Text.FeaturesTimeBlockingExtra1Text}
              </div>
              <StaticImage
                src="../images/features-event-editor.png"
                className={classNames(classes.picture, classes.timeBlockingEditorPicture, classes.desktop)}
                alt={Text.FeaturesTimeBlockingExtra1PictureAlt}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
              <StaticImage
                src="../images/features-event-editor-mobile.png"
                className={classNames(classes.picture, classes.timeBlockingEditorPicture, classes.mobile)}
                alt={Text.FeaturesTimeBlockingExtra1PictureAlt}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
            <div className={classes.timeBlockingExtraBlock}>
              <h3>{Text.FeaturesTimeBlockingExtra2Title}</h3>
              <div className={classes.timeBlockingExtraText}>
                {Text.FeaturesTimeBlockingExtra2Text}
              </div>
              <StaticImage
                src="../images/features-multi-slots.png"
                className={`${classes.picture} ${classes.desktop}`}
                alt={Text.FeaturesTimeBlockingExtra2PictureAlt}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
              <StaticImage
                src="../images/features-multi-slots-mobile.png"
                className={`${classes.picture} ${classes.mobile}`}
                alt={Text.FeaturesTimeBlockingExtra2PictureAlt}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
          </InViewTracker>
        </div>

        <CtaBlock />

        <div className={classes.multipleCalendars}>
          <InViewTracker name="multiple_calendars_title">
            <h2>{Text.FeaturesMultipleCalendarsTitle}</h2>
          </InViewTracker>
          <InViewTracker name="multiple_calendars_text">
            <div className={classes.blockColumns}>
              <div className={classes.multipleCalendarsBlock}>
                <h3>{Text.FeaturesMultipleCalendarsBlock1Title}</h3>
                <div className={classes.multipleCalendarsText}>{Text.FeaturesMultipleCalendarsBlock1Text}</div>
                <StaticImage
                  src="../images/features-multi-calendar-1.png"
                  className={classes.picture}
                  alt={Text.FeaturesMultipleCalendarsBlock1PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className={classes.multipleCalendarsBlock}>
                <h3>{Text.FeaturesMultipleCalendarsBlock2Title}</h3>
                <div className={classes.multipleCalendarsText}>{Text.FeaturesMultipleCalendarsBlock2Text}</div>
                <StaticImage
                  src="../images/features-multi-calendar-2.png"
                  className={classes.picture}
                  alt={Text.FeaturesMultipleCalendarsBlock2PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className={classNames(classes.multipleCalendarsBlock, classes.desktop)}>
                <h3>{Text.FeaturesMultipleCalendarsBlock3Title}</h3>
                <div className={classes.multipleCalendarsText}>{Text.FeaturesMultipleCalendarsBlock3Text}</div>
                <StaticImage
                  src="../images/features-multi-calendar-3.png"
                  className={classNames(classes.picture, classes.multipleCalendarsPicture3)}
                  alt={Text.FeaturesMultipleCalendarsBlock3PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </InViewTracker>
        </div>
      </div>

      <div className={classes.pageContent}>
        <div className={classNames(classes.weeklyPlanning, classes.desktop)}>
          <div className={classes.weeklyPlanningMain}>
            <InViewTracker name="weekly_planning_title">
              <h2>{Text.FeaturesWeeklyPlanningTitle}</h2>
              <h3 className={classes.subtitle}>{Text.FeaturesWeeklyPlanningSubTitle}</h3>
            </InViewTracker>
            <InViewTracker name="weekly_planning_text">
              <ul className={`${classes.list} ${classes.mobile}`}>
                <li>{Text.FeaturesWeeklyPlanningText1}</li>
                <li>{Text.FeaturesWeeklyPlanningText2}</li>
                <li>{Text.FeaturesWeeklyPlanningText3}</li>
              </ul>
              <div className={`${classes.textColumns} ${classes.desktop}`}>
                <div>
                  {Text.FeaturesWeeklyPlanningText1}
                </div>

                <div>
                  {Text.FeaturesWeeklyPlanningText2}
                </div>

                <div>
                  {Text.FeaturesWeeklyPlanningText3}
                </div>
              </div>
            </InViewTracker>

            <InViewTracker name="weekly_planning_video">
              <Video className={classes.weeklyPlanningVideo} video={WeeklyViewVideo} label="weekly_planning" />
            </InViewTracker>
          </div>
        </div>

        <div className={classes.tasks}>
          <InViewTracker name="tasks_title">
            <h2>{Text.FeaturesTasksTitle}</h2>
            <h3 className={classes.subtitle}>{Text.FeaturesTasksSubTitle}</h3>
          </InViewTracker>

          <InViewTracker className={classes.tasksBlocks} name="tasks_text">
            <div className={classes.blockColumns}>
              <div className={classes.tasksBlock}>
                <h3>{Text.FeaturesTasksBlock1Title}</h3>
                <div className={classes.tasksBlockText}>{Text.FeaturesTasksBlock1Text}</div>
                <StaticImage
                  src="../images/features-tasks-1.png"
                  className={`${classes.picture} ${classes.desktop}`}
                  alt={Text.FeaturesTasksBlock1PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
                <StaticImage
                  src="../images/features-tasks-mobile-1.png"
                  className={`${classes.picture} ${classes.mobile}`}
                  alt={Text.FeaturesTasksBlock1PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </div>

              <div className={classes.tasksBlock}>
                <h3>{Text.FeaturesTasksBlock2Title}</h3>
                <div className={classes.tasksBlockText}>{Text.FeaturesTasksBlock2Text}</div>
                <StaticImage
                  src="../images/features-tasks-2.png"
                  className={`${classes.picture} ${classes.desktop}`}
                  alt={Text.FeaturesTasksBlock2PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
                <StaticImage
                  src="../images/features-tasks-mobile-2.png"
                  className={`${classes.picture} ${classes.mobile}`}
                  alt={Text.FeaturesTasksBlock1PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </div>

              <div className={classes.tasksBlock}>
                <h3>{Text.FeaturesTasksBlock3Title}</h3>
                <div className={classes.tasksBlockText}>{Text.FeaturesTasksBlock3Text}</div>
                <StaticImage
                  src="../images/features-tasks-3.png"
                  className={`${classes.picture} ${classes.desktop}`}
                  alt={Text.FeaturesTasksBlock3PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
                <StaticImage
                  src="../images/features-tasks-mobile-3.png"
                  className={`${classes.picture} ${classes.mobile}`}
                  alt={Text.FeaturesTasksBlock1PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>

            <div className={classes.blockColumns}>
              <div className={classes.tasksBlock}>
                <h3>{Text.FeaturesTasksBlock4Title}</h3>
                <div className={classes.tasksBlockText}>{Text.FeaturesTasksBlock4Text}</div>
                <StaticImage
                  src="../images/features-tasks-4.png"
                  className={`${classes.picture} ${classes.desktop}`}
                  alt={Text.FeaturesTasksBlock4PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
                <StaticImage
                  src="../images/features-tasks-mobile-4.png"
                  className={`${classes.picture} ${classes.mobile}`}
                  alt={Text.FeaturesTasksBlock1PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </div>

              <div className={classes.tasksBlock}>
                <h3>{Text.FeaturesTasksBlock5Title}</h3>
                <div className={classes.tasksBlockText}>{Text.FeaturesTasksBlock5Text}</div>
                <StaticImage
                  src="../images/features-tasks-5.png"
                  className={`${classes.picture} ${classes.desktop}`}
                  alt={Text.FeaturesTasksBlock5PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
                <StaticImage
                  src="../images/features-tasks-mobile-5.png"
                  className={`${classes.picture} ${classes.mobile}`}
                  alt={Text.FeaturesTasksBlock1PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </InViewTracker>

          <InViewTracker className={classes.tasksOverdue} name="tasks_overdue">
            <div className={classes.tasksOverdueText}>
              <h3>{Text.FeaturesWeeklyPlanningExtraTitle}</h3>
              <ul className={classes.list}>
                <li>{Text.FeaturesWeeklyPlanningExtraText1}</li>
                <li>{Text.FeaturesWeeklyPlanningExtraText2}</li>
              </ul>
            </div>
            <div
              className={`${classes.tasksOverduePictureWrapper} ${classes.desktop}`}
            >
              <StaticImage
                src="../images/features-overdue.png"
                className={`${classes.picture} ${classes.tasksOverduePicture}`}
                alt={Text.FeaturesTasksBlock1PictureAlt}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
            <StaticImage
              src="../images/features-overdue-mobile.png"
              className={`${classes.picture} ${classes.mobile}`}
              alt={Text.FeaturesTasksBlock1PictureAlt}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </InViewTracker>

          <InViewTracker className={classNames(classes.tasksExtra, classes.desktop)} name="tasks_video">
            <div className={classes.tasksText}>
              <h3>{Text.FeaturesTasksExtraTitle}</h3>
              <div>{Text.FeaturesTasksExtraText}</div>
            </div>
            <Video
              className={classes.tasksExtraVideo}
              video={DatePickerVideo}
              label="tasks"
            />
          </InViewTracker>
        </div>

        <CtaBlock />
      </div>

      <div className={classes.blackBackground}>
        <div className={classes.pageContent}>
          <div className={classes.events}>
            <InViewTracker name="events_title">
              <h2>{Text.FeaturesEventsTitle}</h2>
            </InViewTracker>
            <div className={classes.eventsBlock}>
              <h3 className={classes.subtitle}>{Text.FeaturesEventsSubTitle}</h3>

              <InViewTracker name="events_text">
                <div className={`${classes.textColumns} ${classes.desktop}`}>
                  <div>
                    <ul className={classes.list}>
                      <li>{Text.FeaturesEventsText1}</li>
                      <li>{Text.FeaturesEventsText2}</li>
                    </ul>
                  </div>
                  <div>
                    <ul className={classes.list}>
                      <li>{Text.FeaturesEventsText3}</li>
                      <li>{Text.FeaturesEventsText4}</li>
                    </ul>
                  </div>
                  <div>
                    <ul className={classes.list}>
                      <li>{Text.FeaturesEventsText5}</li>
                      <li>{Text.FeaturesEventsText6}</li>
                    </ul>
                  </div>
                </div>

                <ul className={`${classes.list} ${classes.mobile}`}>
                  <li>{Text.FeaturesEventsText1}</li>
                  <li>{Text.FeaturesEventsText2}</li>
                  <li>{Text.FeaturesEventsText3}</li>
                  <li>{Text.FeaturesEventsText4}</li>
                  <li>{Text.FeaturesEventsText5}</li>
                  <li>{Text.FeaturesEventsText6}</li>
                </ul>
              </InViewTracker>

              <InViewTracker name="events_video" className={classes.desktop}>
                <Video
                  className={classes.eventsVideo}
                  video={EventsVideo}
                  label="events"
                />
              </InViewTracker>

              <InViewTracker name="events_picture" className={classes.mobile}>
                <StaticImage
                  src="../images/features-events-mobile.png"
                  className={classes.picture}
                  alt={Text.FeaturesEventsSubTitle}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </InViewTracker>
            </div>
          </div>

          <div className={classes.notes}>
            <InViewTracker name="notes_title">
              <h2>{Text.FeaturesNotesTitle}</h2>
            </InViewTracker>

            <InViewTracker name="notes_content">
              <div className={classes.notesBlock}>
                <div className={classes.notesBlockInner}>
                  <div className={classes.textColumns}>
                    <div>
                      <ul className={classes.list}>
                        <li>{Text.FeaturesNotesBlock1Text1}</li>
                      </ul>
                    </div>
                    <div>
                      <ul className={classes.list}>
                        <li>{Text.FeaturesNotesBlock1Text2}</li>
                      </ul>
                    </div>
                    <div>
                      <ul className={classes.list}>
                        <li>{Text.FeaturesNotesBlock1Text2}</li>
                      </ul>
                    </div>
                  </div>

                  <StaticImage
                    src="../images/features-notes.png"
                    className={`${classes.picture} ${classes.desktop}`}
                    alt={Text.FeaturesNotesBlock1PictureAlt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />

                  <StaticImage
                    src="../images/features-notes-mobile.png"
                    className={`${classes.picture} ${classes.mobile}`}
                    alt={Text.FeaturesNotesBlock1PictureAlt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>
            </InViewTracker>
          </div>
        </div>
      </div>

      <div className={classes.pageContent}>
        <div className={classes.collections}>
          <InViewTracker className={classes.collectionsHeader} name="collections_title">
            <h2>{Text.FeaturesCollectionsTitle}</h2>
            <h3>{Text.FeaturesCollectionsSubTitle}</h3>
            <div className={classes.collectionsHeaderText}>{Text.FeaturesCollectionsText}</div>
          </InViewTracker>

          <InViewTracker name="collections_text">
            <div className={`${classes.textColumns} ${classes.desktop}`}>
              <div className={classes.collectionsBlock}>
                <div className={classes.collectionsBlockText}>{Text.FeaturesCollectionsBlock1Text}</div>

                <StaticImage
                  src="../images/features-collections-1.png"
                  className={classes.picture}
                  alt={Text.FeaturesCollectionsBlock1PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </div>

              <div className={classes.collectionsBlock}>
                <div className={classes.collectionsBlockText}>{Text.FeaturesCollectionsBlock2Text}</div>

                <StaticImage
                  src="../images/features-collections-2.png"
                  className={classes.picture}
                  alt={Text.FeaturesCollectionsBlock2PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </div>

              <div className={classes.collectionsBlock}>
                <div className={classes.collectionsBlockText}>{Text.FeaturesCollectionsBlock3Text}</div>

                <StaticImage
                  src="../images/features-collections-3.png"
                  className={classes.picture}
                  alt={Text.FeaturesCollectionsBlock3PictureAlt}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>

            <ul className={`${classes.list} ${classes.mobile}`}>
              <li>{Text.FeaturesCollectionsBlock1Text}</li>
              <li>{Text.FeaturesCollectionsBlock2Text}</li>
              <li>{Text.FeaturesCollectionsBlock3Text}</li>
            </ul>

            <StaticImage
              src="../images/features-collections-mobile.png"
              className={classNames(classes.picture, classes.mobile)}
              alt={Text.FeaturesCollectionsBlock1PictureAlt}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </InViewTracker>

          <InViewTracker className={classNames(classes.collectionsExtra, classes.desktop)} name="collections_video">
            <Video
              className={classes.collectionsExtraVideo}
              video={CollectionsVideo}
              label="collections"
            />
          </InViewTracker>
        </div>
      </div>

      <div className={classes.desktop}>
        <CtaBlock />
      </div>

      <Footer />
    </>
  );
}

export default FeaturesPage;
